.container {
  background-color: #e6e6e6;
  order: 1;
  /* padding-left: 2rem; */
  padding-top: 0.5rem;
  max-width: 400px;
  margin-inline: auto;
  padding-inline: 3rem;
  padding-top: 3rem;
}

.title {
  font-size: 28px;
  /* text-align: center; */
  font-family: "Avenir Next", sans-serif;
  font-weight: 600;
  color: #4e4e4e;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.description {
    font-size: 12px;
    font-family: "Avenir Next Medium", sans-serif;
    color: #4e4e4e;
    /* padding-top: 1rem; */
    margin: 0;
  }

  .confirm {
    font-size: 12px;
    font-family: "Avenir Next Medium", sans-serif;
    color: #16a34c;
    padding-top: 1rem;
    margin: 0;
    margin-left: 0.5rem;
  }

.mailForm {
  padding-top: 1rem;
  display: flex;
}

.appStoreButton {
  padding-top: 6rem;
  margin-top: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.textField {
  width: 100%;
  height: 44px;
  padding-left: 1rem;
  font-size: 16px;
  outline: none;
  border: none;
  color: #595959;
  background: #e6e6e6;
  border-radius: 8px;
  box-shadow: inset 2px 2px 5px #babebe, inset -5px -5px 10px #ffffff73;
  -webkit-appearance: none;
}

.errorTextField {
    width: 100%;
    height: 40px;
    padding-left: 1rem;
    font-size: 16px;
    outline: none;
    /* border: none; */
    color: #595959;
    background: #e6e6e6;
    border-radius: 8px;
    box-shadow: inset 2px 2px 5px #babebe, inset -5px -5px 10px #ffffff73;
    -webkit-appearance: none;
    border-color: rgba(255, 0, 0, 0.446);
    
  }

.button {
  margin-left: 1rem;
  width: 124px;
  display: inline;
  color: #595959;
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-inline: 0.3rem;
  border-radius: 8px;
  border: none;
  background: #e6e6e6;
  box-shadow: 4px 4px 10px #c6c6c6, -4px -4px 10px #ffffff;
}

@media (min-width: 768px) {
  .container {
    width: 48%;
    padding-inline: 3rem;
    padding-top: 5rem;
  }

  .title {
    font-size: 42px;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .description {
    padding-top: 1rem;
  }

  .appStoreButton {
    margin-top: 0rem;
  }
}
