

.container {
    order: 1;
    
    padding: 3rem;
    padding-inline: 3rem;
    max-width: 400px;
    margin: auto;
    padding-block: 1rem;

  }

  .containerInverse {
    order: 2;
    
    padding: 3rem;
    padding-inline: 3rem;
    max-width: 400px;
    margin: auto;
    padding-block: 1rem;

  }
  
  .image {
    width: 100%;
    aspect-ratio: auto;
  
    /* border-radius: 16px;
    background: #e6e6e6;
    box-shadow: 4px 4px 10px #c6c6c6, -4px -4px 10px #ffffffbd; */
  }

  @media (min-width: 768px) {
    .container {
        padding: 3rem;
        padding-inline: 4rem;
        width: 45%;
        padding-block: 3rem;

      }
    
      .containerInverse {
        padding: 3rem;
        padding-inline: 4rem;
        width: 45%;
        padding-block: 3rem;

      }
}