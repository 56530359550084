.container {
  padding-block: 1rem;
  padding-left: 2rem;
  display: flex;
  max-width: 1024px;
  margin: auto;
  background-color: #e6e6e6; /*  rgb(219, 255, 241); */
  align-items: center;
}

.titleContainer {
  width: 110px;
  display: flex;
  border-radius: 10px;
  background: #e6e6e6;
  box-shadow: 4px 4px 10px #c6c6c6, -4px -4px 10px #ffffff;
}

.titleIcon {
  padding-left: 0.7rem;
  padding-block: 0.6rem;
  height: 22px;
}

.title {
  font-size: 16px;
  text-align: center;
  font-family: "Avenir Next Medium", sans-serif;
  color: #4e586e;
  margin: auto;
}

.button {
  font-size: 1px;
  text-align: center;
  font-family: "Avenir Next Medium", sans-serif;
  color: #4e586e;
  margin-left: 2rem;
  border: 0px;
  background-color: #e6e6e6;
  cursor: pointer;
  /* remove */
  visibility: visible;
  font-size: 16px;

}

@media (min-width: 576px) {
  .button {
    visibility: visible;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .button {
    visibility: visible;
    font-size: 16px;
  }
}

