.container {
    order: 2;
    max-width: 400px;
    margin-inline: auto;
    padding: 3rem;
    padding-block: 1rem;
}

.containerInverse {
    order: 1;
    max-width: 400px;
    margin-inline: auto;
    padding: 3rem;
    padding-block: 1rem;


}

.title {
    font-size: 20px;
    text-align: center;
    font-family: "Avenir Next Medium", sans-serif;
    color: #4e4e4e;
    padding-top: 1rem;
  }
  
  .description {
    font-size: 14px;
    text-align: center;
    font-family: "Avenir Next Medium", sans-serif;
    color: #4e4e4e;
    padding-top: 0.6rem;
  }

  @media (min-width: 768px) {
    .container {
      width: 48%;
      padding-block: 3rem;
    }

    .containerInverse {
        width: 48%;
        padding-block: 5rem;
      }
  
    .title {
      margin-top: 2rem;
      font-size: 32px;
    }
  
    .description {
      font-size: 18px;
    }
  }