body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e6e6e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Bold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-DemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

a { color: inherit; } 

a:link {
  text-decoration: none;
}

a:hover {
  color: inherit; 
}
