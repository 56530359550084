.container {
  background-color: #e6e6e6;
  display: flex;
  align-items: flex-start;
  order: 2;
  max-width: 500px;
  margin-inline: auto;
  padding-inline: 3rem;
  padding-top: 0rem;
}

.leftImage {
  width: 46%;
  margin-top: 65px;
  margin-right: 8%;
  aspect-ratio: auto;

  border-radius: 16px;
  background: #e6e6e6;
  box-shadow: 4px 4px 10px #c6c6c6, -4px -4px 10px #ffffffbd;
}

.rightImage {
  width: 46%;
  margin-top: 5px;
  aspect-ratio: auto;

  border-radius: 16px;
  background: #e6e6e6;
  box-shadow: 4px 4px 10px #c6c6c6, -4px -4px 10px #ffffffbd;
}

@media (min-width: 768px) {
  .container {
    width: 52%;
    padding-inline: 1rem;
    max-width: 470px;
  }

  .rightImage {
    margin-top: 65px;
  }

  .leftImage {
    margin-top: 15px;
  }
}
