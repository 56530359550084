.container {
    display: block;
    padding-top: 1rem;
  }
  
  @media (min-width: 576px) {
    .container {
      display: block;
      padding-top: 1rem;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      display: flex;
      max-width: 1024px;
      margin: auto;
      padding-top: 2rem;

    }
  }
  
  